import type { IPromo } from '@/modules/cart/types/cart.d'
import { LocalStorageKey } from '@/modules/core/enums'
import { jwtDecode } from 'jwt-decode'
import type { ICustomer } from '~/modules/customer/types/customer'
import useCart from './useCart'

export default async () => {
  try {
    const baseURL = useRuntimeConfig().public.api.awsGateway

    const accessToken = localStorage.getItem(LocalStorageKey.AccessToken) as string

    if (!accessToken) {
      return
    }

    const customer: ICustomer = jwtDecode(accessToken)

    if (customer.exp * 1000 < Date.now()) {
      return
    }

    const path: string = `/v1/customer/users/${customer.id}/promos`

    const { coupons } = useCart()

    coupons.value = await $fetch<IPromo[]>(path, {
      baseURL,
      headers: { AuthToken: accessToken },
    })
  }
  catch {
    const { $toast } = useNuxtApp()

    $toast.error('Erro ao buscar cupons de desconto. Tente novamente.')
  }
}
